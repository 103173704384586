<div
  class="asc-progress-bar govuk-!-margin-right-2 govuk-!-margin-top-1"
  [ngClass]="{ 'govuk-!-margin-bottom-4': header === 'Recruitment' || header === 'Workplace' }"
>
  <div *ngIf="header">
    <h4 class="govuk-!-margin-bottom-2 govuk-!-margin-top-1">{{ header }}</h4>
  </div>
  <ng-container *ngFor="let section of sections; index as i">
    <div class="govuk__flex">
      <div class="govuk-!-margin-right-3">
        <img
          *ngIf="i < currentSectionIndex || completeProgressBar || (i == currentSectionIndex && currentSectionComplete)"
          [attr.data-testid]="'completed-' + i"
          class="govuk-util__vertical-align-top"
          src="/assets/images/progress-bar/done.svg"
          alt=""
        />
        <img
          *ngIf="i === currentSectionIndex && !completeProgressBar && !currentSectionComplete"
          [attr.data-testid]="'currentSection-' + i"
          class="govuk-util__vertical-align-top"
          src="/assets/images/progress-bar/doing.svg"
          alt=""
        />
        <img
          *ngIf="i > currentSectionIndex && !completeProgressBar"
          [attr.data-testid]="'todo-' + i"
          class="govuk-util__vertical-align-top"
          src="/assets/images/progress-bar/todo.svg"
          alt=""
        />
      </div>
      <div class="govuk__flex govuk__align-items-center">
        <span
          class="govuk-!-font-size-16"
          [ngClass]="
            ((currentSectionIndex > i || completeProgressBar) && 'govuk-!-font-weight-bold') ||
            (currentSectionIndex < i && 'asc-colour-grey')
          "
        >
          {{ section }}
        </span>
      </div>
    </div>
    <img
      *ngIf="i < sections.length - 1"
      [attr.data-testid]="'greenLine-' + i"
      class="govuk-!-display-block govuk-!-margin-top-0 govuk-!-margin-bottom-0"
      src="/assets/images/progress-bar/green-line.svg"
      alt=""
    />
  </ng-container>
</div>
