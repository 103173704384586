<app-error-summary *ngIf="serverError" [serverError]="serverError"> </app-error-summary>
<div class="govuk-form-group">
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
      <h1 id="dialogHeading" class="govuk-fieldset__heading">
        Are you sure you want to remove parent status from {{ data.establishmentName }}?
      </h1>
    </legend>
  </fieldset>
</div>

<button type="button" (click)="closeDialogWindow(null)" class="govuk-button">Close</button>
<button
  type="button"
  (click)="removeParentStatus(data)"
  class="govuk-button govuk-button--warning govuk-util__float-right"
>
  Remove parent status
</button>
