<div *ngIf="success" class="alert alert-success">
  <p *ngFor="let message of success">
    {{message}}
  </p>
</div>
<div *ngIf="info" class="alert alert-info">
  <p *ngFor="let message of info">
    {{message}}
  </p>
</div>
<div *ngIf="warning" class="alert alert-warning">
  <p *ngFor="let message of warning">
    {{message}}
  </p>
</div>
<div *ngIf="error" class="alert alert-danger">
  <p *ngFor="let message of error">
    {{message}}
  </p>
</div>
