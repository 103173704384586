<div [formGroup]="form">
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--m govuk-!-margin-bottom-8">
      <h1 class="govuk-fieldset__heading">{{ title }}</h1>
    </legend>

    <ng-container *ngFor="let item of formControlsMap">
      <div class="govuk-form-group" [class.govuk-form-group--error]="submitted && form.get(item.name).errors">
        <label class="govuk-label" for="{{ item.name }}">
          {{ item.label }}
        </label>
        <span *ngIf="submitted && form.get(item.name).errors" id="{{ item.name }}-error" class="govuk-error-message">
          <span class="govuk-visually-hidden">Error:</span> {{ getFirstErrorMessage(item.name) }}
        </span>
        <input
          class="govuk-input govuk-input--width-20"
          [formControlName]="item.name"
          id="{{ item.name }}"
          name="{{ item.name }}"
          [class.govuk-input--error]="submitted && form.get(item.name).errors"
          type="text"
        />
      </div>
    </ng-container>
  </fieldset>

  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--m govuk-!-margin-top-4">
      <h2 class="govuk-fieldset__heading">Permissions</h2>
    </legend>
    <div class="govuk-form-group" [class.govuk-form-group--error]="submitted && form.get('permissionsType').errors">
      <span
        *ngIf="submitted && form.get('permissionsType').errors"
        id="permissionsType-error"
        class="govuk-error-message"
      >
        <span class="govuk-visually-hidden">Error:</span> {{ getFirstErrorMessage('permissionsType') }}
      </span>
      <div class="govuk-radios govuk-!-margin-bottom-2">
        <div class="govuk-radios__item" *ngFor="let item of permissionsTypeRadios; let i = index">
          <input
            class="govuk-radios__input"
            [formControlName]="'permissionsType'"
            id="permissionsType-{{ i }}"
            name="permissionsType"
            value="{{ item.permissionsQuestionValue }}"
            type="radio"
          />
          <label class="govuk-label govuk-radios__label" for="permissionsType-{{ i }}">
            {{ item.label }}
          </label>
        </div>
      </div>
    </div>
  </fieldset>
</div>
