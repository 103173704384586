<div class="govuk-accordion__section" data-testid="accordion" [class]="{ 'govuk-accordion__section--expanded': open }">
  <div class="govuk-accordion__section-header">
    <h2 class="govuk-accordion__section-heading" data-testid="title">
      <button
        type="button"
        (click)="emitToggle()"
        aria-controls="accordion-default-content"
        class="govuk-accordion__section-button"
        [attr.aria-expanded]="open"
        data-testid="showHideButton"
      >
        <span class="govuk-accordion__section-heading-text">
          <span class="govuk-accordion__section-heading-text-focus">{{ title }}</span>
        </span>
        <span class="govuk-hidden govuk-accordion__section-heading-divider"></span>
        <span
          class="govuk-accordion__section-summary govuk-body"
          id="accordion-with-summary-sections-summary-1"
          data-testid="description"
          >{{ description }}</span
        >
        <span class="govuk-accordion__section-toggle" data-nosnippet="">
          <span class="govuk-accordion__section-toggle-focus">
            <span
              class="govuk-accordion-nav__chevron"
              [class]="{ 'govuk-accordion-nav__chevron--up': open, 'govuk-accordion-nav__chevron--down': !open }"
            ></span>
            <span class="govuk-accordion__section-toggle-text">{{ buttonText }}</span>
          </span>
        </span>
      </button>
    </h2>
  </div>
  <div
    class="govuk-accordion__section-content govuk-!-padding-top-0 govuk-!-padding-bottom-7"
    id="accordion-with-summary-sections-content-1"
  >
    <div class="govuk-radios">
      <ng-container *ngFor="let item of items">
        <div class="govuk-radios__item" data-testid="radioButton">
          <input
            class="govuk-radios__input"
            id="{{ formControlName }}-{{ item.id }}"
            [name]="formControlName ? formControlName : controlName"
            type="radio"
            [value]="item.id"
            [checked]="preFilledId === item.id"
            (click)="onClick(item.id)"
          />
          <label for="{{ formControlName }}-{{ item.id }}" class="govuk-label govuk-radios__label">{{
            item.label
          }}</label>
        </div>
      </ng-container>
    </div>
  </div>
</div>
