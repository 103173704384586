<div class="govuk-form-group" [class.govuk-form-group--error]="checkBoxError">
  <fieldset class="govuk-fieldset" aria-describedby="reviewingRegistration-hint">
    <span *ngIf="checkBoxError" id="reviewingRegistration-error" class="govuk-error-message">
      <span class="govuk-visually-hidden">Error:</span>
      {{ checkBoxError }}
    </span>
    <p data-testid="banner">
      <strong class="govuk-tag" [ngClass]="setStatusClass()">
        {{ registration.establishment.status | uppercase }}
      </strong>
    </p>
    <div
      class="govuk-checkboxes"
      *ngIf="registration.establishment.reviewer === userFullName || !registration.establishment.inReview"
    >
      <div class="govuk-checkboxes__item">
        <input
          class="govuk-checkboxes__input"
          id="reviewingRegistration"
          name="reviewingRegistration"
          type="checkbox"
          data-testid="reviewingRegistrationCheckbox"
          [checked]="registration.establishment.inReview"
          (change)="toggleCheckbox($event.target)"
        />
        <label class="govuk-label govuk-checkboxes__label" for="reviewingRegistration">
          I am reviewing this request
        </label>
      </div>
    </div>
    <div *ngIf="registration.establishment.reviewer !== userFullName && registration.establishment.inReview">
      <p>{{ registration.establishment.reviewer }} is reviewing this request</p>
    </div>
  </fieldset>
</div>
