<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <p class="govuk-heading-s">
      By submitting this record, you are confirming the mandatory WDF information provided is up-to-date.
    </p>
    <div class="govuk-button-group govuk-!-margin-top-2">
      <button type="button" (click)="onConfirm($event)" class="govuk-button govuk-!-margin-right-8">
        Confirm and submit
      </button>
      <a [routerLink]="exitUrl.url" [fragment]="exitUrl.fragment" class="govuk-button govuk-button--secondary">
        Exit without submitting
      </a>
    </div>
  </div>
</div>
