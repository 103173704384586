<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <h1 class="govuk-heading-l" data-testid="heading">{{ title }}</h1>
    <p data-testid="firstParagraph">
      Please call the ASC-WDS Support Team on <strong>0113 241 0969</strong> and tell them that you're trying to
      {{ flow === 'registration' ? 'create an account' : 'add a new workplace' }}.
    </p>
    <p>The Support Team is available Monday to Friday, 9am to 5pm (except on public holidays).</p>
  </div>
</div>
