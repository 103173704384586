<div [class.govuk-form-group--error]="hasError">
  <div class="govuk-accordion" data-module="govuk-accordion">
    <div class="govuk-accordion__controls">
      <button type="button" (click)="toggleAll()" class="govuk-accordion__show-all" [attr.aria-expanded]="showAll">
        <span
          class="govuk-accordion-nav__chevron"
          [class]="{ 'govuk-accordion-nav__chevron--up': showAll, 'govuk-accordion-nav__chevron--down': !showAll }"
        ></span>
        <span class="govuk-accordion__show-all-text" data-testid="toggleText">{{ toggleText }}</span>
      </button>
    </div>

    <p *ngIf="hasError" id="{{ formControlName }}-error" class="govuk-error-message">
      <span class="govuk-visually-hidden">Error:</span> {{ errorMessage }}
    </p>
    <div *ngFor="let accordion of accordions">
      <app-radio-button-accordion
        [controlName]="formControlName"
        [title]="accordion.title"
        [description]="accordion.descriptionText"
        [items]="accordion.items"
        [open]="accordion.open"
        (toggleEmitter)="toggleAccordion(accordion.index)"
        (selectedValueEmitter)="onClick($event)"
        [preFilledId]="preFilledId"
      ></app-radio-button-accordion>
    </div>
  </div>
</div>
