<div class="govuk-radios" [formGroup]="form">
  <div class="govuk-radios__item" *ngFor="let item of locationAddresses; let i = index">
    <input
      class="govuk-radios__input"
      id="workplace-{{ i }}"
      value="{{ i }}"
      name="workplace"
      type="radio"
      [formControlName]="'workplace'"
    />
    <label class="govuk-label govuk-radios__label" for="workplace-{{ i }}">
      {{ item.locationName ? (item.locationName | titlecase) : (item.addressLine1 | titlecase) }},
      {{ item.townCity | titlecase }},
      {{ item.postalCode }}
    </label>
  </div>
</div>
