<div class="govuk-!-margin-top-5 govuk-!-margin-bottom-7">
  <ul class="govuk-tabs__list asc-tabs__list" role="tablist">
    <li
      class="govuk-tabs__list-item asc-tabs__list-item"
      data-testid="workplaceListItem"
      [class.asc-tabs__list-item--active]="!viewWDFData"
    >
      <a
        id="tab_workplace"
        class="govuk-tabs__tab asc-tabs__link"
        [class.asc-tabs__link--active]="!viewWDFData"
        href="#"
        role="tab"
        (click)="this.handleViewChange($event)"
        >Workplace</a
      >
      <i
        [class.govuk-tabs__tab--alert]="workplaceAlert"
        [class.govuk-tabs__tab--red-cross]="workplaceRedCross"
        [class.govuk-tabs__tab--green-tick]="workplaceGreenTick"
      >
        <span *ngIf="workplaceAlert" class="govuk-visually-hidden" data-testid="orange-flag">Orange warning flag</span>
        <span *ngIf="workplaceRedCross" class="govuk-visually-hidden" data-testid="red-cross">Red cross</span>
        <span *ngIf="workplaceGreenTick" class="govuk-visually-hidden" data-testid="green-tick">Green tick</span>
      </i>
    </li>
    <li
      class="govuk-tabs__list-item asc-tabs__list-item"
      data-testid="staffListItem"
      [class.asc-tabs__list-item--active]="viewWDFData"
    >
      <a
        id="tab_staff"
        class="govuk-tabs__tab asc-tabs__link"
        [class.asc-tabs__link--active]="viewWDFData"
        href="#"
        role="tab"
        (click)="this.handleViewChange($event)"
        >Staff records</a
      >
      <i
        [class.govuk-tabs__tab--alert]="staffAlert"
        [class.govuk-tabs__tab--green-tick]="staffGreenTick"
        [class.govuk-tabs__tab--red-cross]="staffRedCross"
      >
        <span *ngIf="staffAlert" class="govuk-visually-hidden" data-testid="staff-orange-flag"
          >Orange warning flag</span
        >
        <span *ngIf="staffRedCross" class="govuk-visually-hidden" data-testid="staff-red-cross">Red cross</span>
        <span *ngIf="staffGreenTick" class="govuk-visually-hidden" data-testid="staff-green-tick">Green tick</span>
      </i>
    </li>
  </ul>
</div>
<ng-content></ng-content>
