<form #formEl (ngSubmit)="addNote()" [formGroup]="notesForm">
  <div class="govuk-form-group govuk-!-padding-right-9" [class.govuk-form-group--error]="notesError">
    <span *ngIf="notesError" id="addNote-error" class="govuk-error-message">
      <span class="govuk-visually-hidden">Error:</span>
      {{ notesError }}
    </span>
    <label for="notes" class="govuk-label govuk-!-font-weight-bold">Notes</label>
    <textarea
      class="govuk-textarea"
      name="notes"
      id="notes"
      rows="4"
      data-testid="notesTextbox"
      [formControlName]="'notes'"
    ></textarea>
  </div>
  <button type="submit" class="govuk-button govuk-button--secondary">Add this note</button>
</form>
<dl class="govuk-summary-list" *ngIf="notes" data-testid="notesList">
  <div class="govuk-summary-list__row" *ngFor="let note of notes">
    <dt class="govuk-summary-list__key--no-border govuk-!-display-block govuk-!-font-weight-bold">
      {{ note.user.FullNameValue }}, {{ note.createdAt | date: 'dd MMMM yyyy hh:mm a' }}
    </dt>
    <dd class="govuk-summary-list__value govuk-!-display-block govuk-!-margin-bottom-4">{{ note.note }}</dd>
  </div>
</dl>
